/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    fill="currentColor"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 449 389"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M164.6 107.038c-1.9.2-3.7 1.2-4.9 2.7l-40.2 52c-1 1.2-1.5 2.8-1.5 4.4v108.8c0 3.9 3.2 7.1 7.1 7.1h198.7c3.9 0 7.1-3.2 7.1-7.1v-108.8c0-1.6-.5-3.1-1.5-4.4l-40.2-52c-1.3-1.7-3.4-2.7-5.6-2.7H165.4c-.3-.1-.5-.1-.8 0zm4.2 14.2h48.6v37.8h-77.8l29.2-37.8zm62.8 0h48.6l29.3 37.8h-77.8v-37.8h-.1zm-99.3 52h184.5v94.6H132.3v-94.6zm68.8 4.7c-3.9 0-7.1 3.2-7.1 7.1v18.9c0 3.9 3.2 7.1 7.1 7.1h47.3c3.9 0 7.1-3.2 7.1-7.1v-18.9c0-3.9-3.2-7.1-7.1-7.1h-47.3zm7.1 14.2h33.1v4.7h-33.1v-4.7zm55.8 37.8c-3.9.2-6.9 3.5-6.7 7.5.2 3.9 3.5 6.9 7.5 6.7H305c3.9.1 7.1-3.1 7.2-7 .1-3.9-3.1-7.1-7-7.2H264zm0 19c-3.9.2-7 3.5-6.8 7.4.2 3.9 3.5 7 7.4 6.8h40.3c3.9.1 7.1-3.1 7.2-7 .1-3.9-3.1-7.1-7-7.2H264z"
    />
  </svg>
);

export default SvgComponent;

import PropTypes from 'prop-types';
import * as React from 'react';
import { IoIosCheckmark } from 'react-icons/io';

const H3Check = ({ title }) => (
  <h3 className="flex items-center">
    <IoIosCheckmark className="flex-shrink-0 text-4xl text-brand-orange" />
    <span className="">{title}</span>
  </h3>
);

H3Check.propTypes = {
  title: PropTypes.string,
};

export default H3Check;

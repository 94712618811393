/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const ContainerIcon = (props) => (
  <svg
    fill="currentColor"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 449 389"
    {...props}
  >
    <g fillRule="nonzero">
      <path d="M361.8 150.6c2.2-2.3 3.4-5.1 3.4-8.3v-16.2c0-6.4-5.6-12-12-12H95.9c-6.4 0-12 5.6-12 12v16.2c0 2.8 1.2 5.4 1.7 6.4l.5.9 5.7 6.4v76.8l-6.1 6.8-.5 1.5c-.1.3-.2.5-.2.7-.4 1.1-1 2.7-1 4.7v16.2c0 6.9 5.2 12 12 12h256.8c6.6 0 12-5.4 12-12v-16.2c0-2.6-1-6.1-3.8-8.4l-4.4-5v-76.8l5.2-5.7zm-12.4-9.8l-8.7 9.7v88.9l8.3 9.3v10.5H99.6v-11.4l7.9-8.8v-88.9l-7.9-8.8v-11.4h249.8v10.9z" />
      <path d="M156.7 235.1c4.4 0 7.6-2.7 7.6-6.4v-68.4c0-3.7-3.2-6.4-7.6-6.4-4.4 0-7.6 2.7-7.6 6.4v68.4c0 3.7 3.2 6.4 7.6 6.4zm45.1 0c4.4 0 7.6-2.7 7.6-6.4v-68.4c0-3.7-3.2-6.4-7.6-6.4-4.4 0-7.6 2.7-7.6 6.4v68.4c-.1 3.7 3.1 6.4 7.6 6.4zm45 0c4.4 0 7.6-2.7 7.6-6.4v-68.4c0-3.7-3.2-6.4-7.6-6.4-4.4 0-7.6 2.7-7.6 6.4v68.4c0 3.7 3.2 6.4 7.6 6.4zm45.1 0c4.4 0 7.6-2.7 7.6-6.4v-68.4c0-3.7-3.2-6.4-7.6-6.4-4.4 0-7.6 2.7-7.6 6.4v68.4c-.1 3.7 3.1 6.4 7.6 6.4z" />
    </g>
  </svg>
);

export default ContainerIcon;

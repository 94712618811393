import * as React from 'react';

const WhyCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      What sets us <br />
      apart from <br />
      the <span className="text-brand-orange">rest?</span>
    </p>
  </article>
);

export default WhyCTA;

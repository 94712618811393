import * as React from 'react';

import { Hero, Layout, SEO } from '../components';
import Solutions from '../components/solutions';
import Banner from '../components/why/why-banner';
import CTA from '../components/why/why-cta';
import FirstSection from '../components/why/why-first-section';
import HeroImage from '../components/why/why-hero-image';
import SecondSection from '../components/why/why-second-section';

const WhyPage = () => (
  <Layout formName="why-page-bottom-form" formTitle="Get in touch with us">
    <SEO
      title="Why Choose Us | Relocation Specialists | Frontline Removals"
      description="Why choose us? We’re experienced. We’re specialised. We’re niche. Need another reason? Find out what makes us special."
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    <FirstSection />
    <Solutions />
    <SecondSection />
    <Banner />
  </Layout>
);

export default WhyPage;

import * as React from 'react';

const WhyFirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">Why </span>
        <br />
        <span className="inline-block">choose </span>
        <br />
        <span className="inline-block">us?</span>
      </h1>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>
          Frontline Removals: New South Wales trusted relocation specialists
        </h2>
        <p>
          When it comes to relocating and choosing the best shipping service, we
          understand it can be quite a daunting process. Uplifting your life,
          packing it up in boxes and shipping across the country — there's a lot
          to think about!
        </p>
        <h3>You’re probably thinking...</h3>
        <ul>
          <li>How will I be sure my belongings are safe?</li>
          <li>Will my furniture arrive on time?</li>
          <li>What if my new home isn’t ready yet?</li>
        </ul>
        <h3>
          We focus on alleviating these worries — taking the headache out of
          removals!
        </h3>
        <p>
          With industry insight, meaningful customer service and two decades of
          expertise — we’re New South Wales honest, efficient, and safe removals
          company.
        </p>
        <p>Ship with us, and we’ll take care of your every need!</p>
        <h2>What makes us unique?</h2>
        <p>
          Not only are we experienced and highly-regarded throughout New South
          Wales, and the wider Australian community, we also offer a specialised
          method. Our relocation services are like no other.
        </p>
        <p>
          How? At Frontline, we utilise purpose-built shipping containers for a
          variety of uses. Shipping containers that increase opportunities and
          reduce barriers such as double-handling.
        </p>
        <h3>With Frontline, the opportunities are limitless.</h3>
      </div>
    </div>
  </article>
);

export default WhyFirstSection;

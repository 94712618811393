import * as React from 'react';

import H3Check from '../utils/h3-check';

const WhyFirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h2 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">What makes </span>
        <br />
        <span className="inline-block">us the best </span>
        <br />
        <span className="inline-block">option for </span>
        <br />
        <span className="inline-block">you?</span>
      </h2>
    </div>
    <div className="w-full mt-6 ml-auto md:mt-0 md:w-2/3">
      <div className="rich-text">
        <H3Check title="We’re customer focused" />
        <p>
          When it comes down to it, it's all about you — at Frontline Removals,
          customer service is key. Our processes are built from understanding
          what our clients want, and prioritising their needs.
        </p>
        <H3Check title="We foster meaningful customer conversations" />
        <p>
          Frontline boasts a dedicated customer service team. This makes our
          business approachable, supportive and ready to handle any queries or
          concerns you may have.
        </p>
        <H3Check title="We instil honesty, trust and integrity into our work" />
        <p>
          With strict security procedures, a wealth of industry experience and a
          highly professional team; you can ensure your belongings are safe with
          us.
        </p>
        <H3Check title="Our services are reliable and end-to-end" />
        <p>
          We’re door-to-door — our team are proficient in packing, handling and
          unpacking. Our expertise makes for a highly reliable service.
        </p>
        <h2>Helping people is what we do</h2>
        <p>
          Our team offers a personal, dedicated service. One we all need in such
          a life-changing experience.
        </p>
        <p>
          Together, <em>our</em> family-owned business assists <em>your</em>{' '}
          family — empowering you to turn a stressful process into an easygoing
          experience. Working together, we create a simple and seamless move.
        </p>
      </div>
    </div>
  </article>
);

export default WhyFirstSection;

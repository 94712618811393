import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import * as React from 'react';

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "why-hero" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100, traceSVG: { color: "#152735" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Image
      fluid={data.file.childImageSharp.fluid}
      loading="eager"
      style={{ minHeight: `25rem`, maxHeight: `30rem` }}
      imgStyle={{ objectPosition: `top` }}
      alt="two frontline removalist crew loading stuff "
    />
  );
};

export default HeroImage;

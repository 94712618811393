/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const MovingIcon = (props) => (
  <svg
    fill="currentColor"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 449 389"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M164 168.1c.1-4.4-3.4-8-7.8-8.1h-43.3c-4.4.2-7.8 4-7.5 8.3.2 4.4 3.9 7.8 8.3 7.6H156c4.3.1 7.9-3.4 8-7.8zm-50.4-29.2H156c4.4.1 8-3.4 8-7.8.1-4.4-3.4-8-7.8-8.1h-43.3c-4.4.2-7.8 4-7.5 8.3.1 4.4 3.8 7.8 8.2 7.6zm0 18.5H156c4.4.1 8-3.4 8-7.8.1-4.4-3.4-8-7.8-8.1h-43.3c-4.4.2-7.8 4-7.5 8.3.1 4.4 3.8 7.8 8.2 7.6z"
    />
    <path
      fillRule="nonzero"
      d="M342.6 187.7l-31.7-53c-1.4-2.4-4-3.8-6.8-3.8h-37v-21.2c0-4.4-3.6-7.9-7.9-7.9H139.5c-4.1.4-7.1 3.9-7.1 7.9v2.6c0 4.4 3.5 8 7.9 8 2.3 0 4.5-1 6-2.7h105v71.5H148v-2.6c.1-4.4-3.4-8-7.8-8.1-4.4-.1-8 3.4-8 7.8v69.1c0 4.4 3.6 7.9 7.9 7.9h14.3c3.6 13.7 16 23.8 30.7 23.8s27.1-10.2 30.7-23.8h44.5c3.6 13.7 16 23.8 30.7 23.8s27.1-10.2 30.7-23.8H336c4.4 0 7.9-3.6 7.9-7.9v-63.6c-.1-1.3-.5-2.7-1.3-4zM267 146.8h32.6l25.4 42.4h-58v-42.4zm-82 124.5c-8.9 0-15.9-7-15.9-15.9s7-15.9 15.9-15.9 15.9 7 15.9 15.9-7 15.9-15.9 15.9zm105.8 0c-8.9 0-15.9-7-15.9-15.9s7-15.9 15.9-15.9 15.9 7 15.9 15.9-7 15.9-15.9 15.9zm37.1-23.8h-6.4c-3.6-13.6-16-23.8-30.7-23.8s-27.1 10.2-30.7 23.8h-44.5c-3.6-13.6-16-23.8-30.7-23.8s-27.1 10.2-30.7 23.8H148v-42.4h179.9v42.4z"
    />
  </svg>
);

export default MovingIcon;
